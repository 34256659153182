import { FC, useEffect, useState } from "react";
import ServerGrid, { ServerGridProps } from "./common/ServerGrid";
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
} from "../app/services/product";
import { FilterOperator, ProductFilterColumn } from "../constants/enums";
import { useFormik } from "formik";
import { createProductValidationSchema } from "../util/validateSchema";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridFilterModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import { getProductColumns } from "../util/gridColumns/productColumns";
import { getPageNumbers } from "../util/getPageNumbers";
import { useAppDispatch } from "../app/hooks";
import {
  useProductPaginationModel,
  setProductPaginationModel,
} from "../app/features/productSlice";
import { useNavigate } from "react-router-dom";
import { setCurrentProductModel } from "../app/features/manageProductSlice";
import BreadcrumbsControl from "./common/BreadCrumbs";

const Products: FC = () => {
  const productColumns = prepareColumns();
  const [rows, setRows] = useState<Product[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterOperator, setFilterOperator] = useState(FilterOperator.Equals);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [openCreateProductDialog, setOpenCreateProductDialog] = useState(false);
  const [openUpdateProductDialog, setOpenUpdateProductDialog] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const dispatch = useAppDispatch();
  const paginationModel = useProductPaginationModel();
  const setPaginationModel = (paginationModel: PaginationModel) =>
    dispatch(setProductPaginationModel(paginationModel));
  const navigate = useNavigate();

  const [filter, setFilter] = useState<GetProductsSearchCriteria>({
    id: undefined,
    byName: undefined,
    sortField: "productName",
    sortDir: "asc",
  });

  const [filterColumn, setFilterColumn] = useState(
    productColumns.filter((column) => column.filterable).at(0)
      ?.field as ProductFilterColumn // set first filterable column as default filter column
  );

  const [selectedRow, setSelectedRow] = useState<Product>({
    id: "",
    productName: "",
    productType: "Web",
    description: "",
  });

  /*
   * Formik
   * */

  const createProductFormik = useFormik({
    initialValues: {
      productName: "",
      productType: "Web",
      description: "",
    } as CreateProduct,

    validationSchema: createProductValidationSchema,

    onSubmit: async (values, { resetForm }) => {
      // Update product
      if (openUpdateProductDialog) {
        var payload = await updateProduct({
          productId: selectedRow.id,
          product: values,
        });

        setSnackbarMessage(
          getApiErrorMessage(payload, "Product updated successfully")
        );
        setIsSnackbarOpen(true);
      } else {
        // Create product
        var payload = await createProduct(values);
        setSnackbarMessage(
          getApiErrorMessage(payload, "Product created successfully")
        );
        setIsSnackbarOpen(true);
      }

      resetForm();
      refetchProducts();
    },
  });

  /*
   * API calls
   * */

  const [
    createProduct,
    {
      error: createProductError,
      isError: createProductIsError,
      isLoading: createProductIsLoading,
      isSuccess: createProductIsSuccess,
    },
  ] = useCreateProductMutation();

  const {
    data: products,
    isFetching: productsIsFetching,
    isSuccess: productIsSuccess,
    isError: productsIsError,
    error: productsError,
    refetch: refetchProducts,
  } = useGetProductsQuery(
    {
      ...filter,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    },
    {
      refetchOnMountOrArgChange: true, 
    }
  );
  

  const [
    updateProduct,
    {
      error: updateProductError,
      isError: updateProductIsError,
      isLoading: updateProductIsLoading,
      isSuccess: updateProductIsSuccess,
    },
  ] = useUpdateProductMutation();

  const [
    deleteProduct,
    {
      error: deleteProductError,
      isError: deleteProductIsError,
      isLoading: deleteProductIsLoading,
      isSuccess: deleteProductIsSuccess,
    },
  ] = useDeleteProductMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    if (productIsSuccess && products?.data) {
      // Filter out the IDMSPortal product
      const filteredProducts = products.data.filter(
        (p: { productName: string }) => p.productName !== "IDMSPortal"
      );

      setRowCountState(filteredProducts.length || 0);
      setRows(filteredProducts);
    }
  }, [productIsSuccess, products]);

  useEffect(() => {
    (createProductIsError || createProductIsSuccess) &&
      handleCreateProductDialogClose();
  }, [createProductIsError, createProductIsSuccess]);

  useEffect(() => {
    (updateProductIsError || updateProductIsSuccess) &&
      handleUpdateProductDialogClose();
  }, [updateProductIsError, updateProductIsSuccess]);

  useEffect(() => {
    if (productsIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...productsError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [productsIsError]);

  /*
   * Handlers
   * */

  function prepareColumns() {
    const setActions = (params: GridRowParams<any>) => {
      return [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          title="Edit Product"
          onClick={() => {
            setSelectedRow(params.row);

            createProductFormik.setValues({
              ...createProductFormik.values,
              productName: params.row.productName,
              productType: params.row.productType,
              description: params.row.description,
            });

            handleUpdateProductDialogOpen(params.row);
          }}
        />,
      ];
    };

    return getProductColumns(setActions);
  }

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const handleCreateProductDialogOpen = () => {
    dispatch(
      setCurrentProductModel({
        id: "",
        productName: "",
        description: "",
        productType: "Web",
      })
    );
    localStorage.removeItem("newlyCreatedProduct");
    navigate("/dashboard/manage-products");
  };
  const handleCreateProductDialogClose = () => {
    setOpenCreateProductDialog(false);
    createProductFormik.resetForm();
  };

  const handleUpdateProductDialogOpen = (product: any) => {
    dispatch(
      setCurrentProductModel({
        id: product?.id,
        productName: product?.productName,
        description: product?.description,
        productType: product?.productType,
      })
    );
    localStorage.removeItem("newlyCreatedProduct");
    navigate("/dashboard/manage-products", {
      state: { selectedProductId: product?.id },
    });
  };

  const handleUpdateProductDialogClose = () =>
    setOpenUpdateProductDialog(false);

  const handleClickDelete = async () => {
    var payload = await deleteProduct(selectedRow.id as string);

    let message = "";
    if ((payload as any)?.error?.data?.infoMessage) {
      message = (payload as any)?.error?.data?.infoMessage;
    } else message = "Product deleted successfully";

    setSnackbarMessage(message);
    setIsSnackbarOpen(true);

    refetchProducts();
    setConfirmationDialogOpen(false);
  };

  const totalPages = Math.ceil(
    (rowCountState ?? products?.rowCount) / paginationModel.pageSize
  );

  const pageNumbers = getPageNumbers(totalPages, paginationModel.page + 1);

  const onFilterChange = (model: GridFilterModel) => {
    const newFilter: GetProductsSearchCriteria = {
      ...(model.items.length > 0 && {
        id: model.items[0].field === "id" ? model.items[0].value : undefined,
        byName:
          model.items[0].field === "productName"
            ? model.items[0].value
            : undefined,
      }),
    };
    setFilter(newFilter);
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };

  const handleSearch = (searchText: string) => {
    const newFilter: GetProductsSearchCriteria = {
      ...filter,
      byName: searchText === "" ? undefined : searchText,
    };
    setFilter(newFilter);
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };
  const handleSortChange = (sortModel: GridSortModel) => {
    const field = sortModel[0]?.field;
    const sort: string | undefined = sortModel[0]?.sort ?? undefined;

    if (field) {
      const newFilter: GetProductsSearchCriteria = {
        ...filter,
        sortField: field,
        sortDir: sort,
      };
      setFilter(newFilter);
      setPaginationModel({
        ...paginationModel,
        page: 0,
      });
    }
  };

  const serverGridProps: ServerGridProps = {
    rows,
    onSortModelChange: handleSortChange,
    columns: productColumns,
    onFilterChange,
    paginationModel,
    rowCount: rowCountState ?? products?.rowCount ?? 0,
    setPaginationModel,
    rowSelectionModel,
    setRowSelectionModel,
    isLoading: productsIsFetching,
    keepNonExistentRowsSelected: true,
    pageNumbers,
    totalPages,
    filterColumn,
    setFilterColumn,
    filterOperator,
    setFilterOperator,
    filterValue,
    setFilterValue,
    hiddenColumns: ["id"],
    usageType: "Product",
  };

  return (
    <Box id="base-padding">
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={
            createProductIsSuccess ||
            updateProductIsSuccess ||
            deleteProductIsSuccess
              ? "success"
              : "error"
          }
          onClose={handleSnackbarClose}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <BreadcrumbsControl page={"Products"} />
      <Box mx={3}>
        <Box display={"flex"} my={2}>
          <TextField
            id="search"
            margin="dense"
            size="small"
            placeholder="Search by Product Name"
            autoComplete={"true"}
            fullWidth
            value={searchTerm}
            onKeyUp={(e) => {
              if (e?.key === "Enter" && searchTerm.trim() !== "")
                handleSearch(searchTerm);
              else if (searchTerm.trim().length === 0) handleSearch("");
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              backgroundColor: "background.paper",
              "&:hover": {
                backgroundColor: "background.default",
              },
              "& .Mui-focused": {
                borderColor: "primary.main",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear search"
                    onClick={() => {
                      setSearchTerm("");
                      handleSearch("");
                    }}
                    edge="end"
                    title="Clear search"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Add New Product">
            <Button
              sx={{
                paddingRight: "15px",
                color: "white",
                margin: "10px",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
              onClick={handleCreateProductDialogOpen}
            >
              <AddIcon />
              <Typography variant="button">Product</Typography>
            </Button>
          </Tooltip>
        </Box>
        <ServerGrid {...serverGridProps} />
      </Box>
    </Box>
  );
};

export default Products;

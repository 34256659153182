import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { logout } from "../features/authSlice";

const baseQuery = fetchBaseQuery({
 // baseUrl: "https://csidealerdev.azurewebsites.net",
 baseUrl:  process.env.REACT_APP_Everest_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const auth = {
      username: "Test2",
      password: "LGIoaaQ1OumQ",
    };

    if (auth.username && auth.password) {
      headers.set(
        "Authorization",
        `Basic ${btoa(`${auth.username}:${auth.password}`)}`
      );
    }
    return headers;
  },
});

const baseQueryWithCustomAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if (result.error && [401, 500].includes(result.error.status as number)) {
  //   api.dispatch(logout());
  //   window.location.href = "/login";
  // }

  return result;
};

export const everestApi = createApi({
  reducerPath: "everestApi",
  baseQuery: baseQueryWithCustomAuth,
  tagTypes: ["SalesTax"],
  endpoints: () => ({}),
});
